import { useEffect } from "react";
import "./App.css";

const App = () => {
  useEffect(() => {
    window.location.href = "https://recruiter.eventbeep.com";
  }, []);

  return null;
};

export default App;